import * as React from 'react';
import Layout from '../components/layout/Layout';
import Seo from '../components/seo/Seo';
import { GlobalError } from '@dnb/eufemia/components';

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="404: Siden finnes ikke" />
      <GlobalError back={false} status="404" />
    </Layout>
  );
};

export default NotFoundPage;
